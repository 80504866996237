<template>
  <div class="main p-35">
    <div class="cut_off p-b-10 flex" v-if="isShow">
      <div class="font24 color-ffa flex-1">中医育儿</div>
      <div class="flex-cen flex-1">
        <Input v-model="value" placeholder="搜索关键字...">
          <Icon type="ios-search" slot="prefix"
        /></Input>
        <div style="width: 50px" class="m-l-10 hand" @click="searchBtn()">
          搜索
        </div>
      </div>
    </div>
    <div v-if="isShow">
      <div
        class="flex-between br-bot p-b-13 hand"
        v-for="(item, i) in foodList"
        :key="i"
        @click="detBtn(item.dataId)"
      >
        <div class="m-t-18">
          <div class="font18 color333">{{ item.title }}</div>
          <div class="font14 color999" v-html="item.abstract"></div>
        </div>
        <div v-if="iconDown">
          <img src="../assets/images/icon_39 (1).png" alt="" />
        </div>
        <div v-else><img src="../assets/images/icon_40.png" alt="" /></div>
      </div>
      <!-- <div class="text-c">
        <Page
          @on-change="change"
          :total="total"
          :page-size="per_page"
          :current="current_page"
          prev-text="上一页"
          next-text="下一页"
        />
        <slot>
          <span class="ml20"
            >共有 <span class="co36">{{ total }}</span> 条数据</span
          >
        </slot>
      </div> -->
    </div>
    <div v-if="isShowDetail">
      <div class="text-r font14 color999 hand" @click="returnBtn()">
        返回上一级
      </div>
      <div
        class="text-c font24 colorff9 m-b-24"
        v-html="detailShow.title"
      ></div>
      <div class="font14 color666" v-html="detailShow.content"></div>
    </div>
  </div>
</template>

<script>
import { medicineFindApi, contentDetailsApi } from "@/api/cyclo";
export default {
  data() {
    return {
      detailShow: {},
      isShow: true,
      isShowDetail: false,
      foodList: [],
      value: "",
      iconDown: true,
      total: 0, //数据总数
      order_page: 1, //页码
      current_page: 1, //当前页码
      per_page: 0, //每页显示条数
      last_page: 0, //最后一页
    };
  },
  methods: {
    // 返回上一层
    returnBtn() {
      this.isShow = true;
      this.isShowDetail = false;
    },
    //分页
    change(v) {
      this.order_page = v;
    },
    // 查看详情
    detBtn(dataId) {
      this.iconDown = !this.iconDown;
      this.isShow = false;
      this.isShowDetail = true;
      contentDetailsApi({
        dataId: dataId,
        toolCode: "1140447530793250818",
      }).then((res) => {
        this.detailShow = res.data;
      });
    },
    // 搜索
    searchBtn() {
      medicineFindApi({
        searchKey: this.value,
        toolCode: "1140447530793250818",
        pageNum: 1,
        pageSize: 15,
      }).then((res) => {
        this.foodList = res.data.data;
        this.total = res.data.total;
      });
    },
    getList() {
      medicineFindApi({
        toolCode: "1140447530793250818",
        pageNum: 1,
        pageSize: 15,
      }).then((res) => {
        this.foodList = res.data.data;
        this.total = res.data.total;
      });
    },
    getDel() {},
  },
  created() {
    this.getList();
  },
};
</script>

<style lang="scss" scoped>
.main {
  height: 695px;
  overflow: auto;
  background: #ffffff;
  .cut_off {
    border-bottom: 1px solid #ffa54b;
  }
  .br-bot {
    border-bottom: 1px solid #e2e2e2;
  }
}
</style>